import { initializeApp } from "firebase/app";

import { getDatabase } from "firebase/database";

function StartFirebase()
{
  let config = {
        apiKey: "AIzaSyDr8Jsh7HrFYLSmzwMRwQQvMkh7tk3QjnQ",
        authDomain: "ihmbilisim-a2e6b.firebaseapp.com",
        databaseURL: "https://ihmbilisim-a2e6b.firebaseio.com",
        projectId: "ihmbilisim-a2e6b",
        storageBucket: "ihmbilisim-a2e6b.appspot.com",
        messagingSenderId: "1090420107402",
        appId: "1:1090420107402:web:69150f7bb573ea5e366573",
        measurementId: "G-JV43VDR381"
  };
  const app = initializeApp(config);
  return  getDatabase(app);
}




export default StartFirebase;