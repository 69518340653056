import React from 'react'
import Header from '../bilgiler/header'
import Weather from '../bilgiler/weather'
import Video from '../bilgiler/video'
import Gallery from '../bilgiler/gallery'
import Cards from '../bilgiler/cards'
import Edebiyat from '../bilgiler/edebiyat'
import Gunhafta from '../bilgiler/gunhafta'
import Biliyormuydunuz from '../bilgiler/biliyormuydunuz'
import Banner from '../bilgiler/banner'
function Home() {
  return (
 <div id="Home" >
 <div className="content-wrapper" >

  {/* /.content-header */}
  {/* Main content */}
  <section className="content" >
    <div className="container-fluid">
      {/* Small boxes (Stat box) */}
      <div className="row">
      
        <Cards></Cards>
       
      </div>
      {/* /.row */}
      <div className="row">
          <div className='col-12 col-md-6 col-lg-6 col-xl-6'>
          <Video></Video>

          </div>
          <div className='col-12 col-md-6 col-lg-6 col-xl-6'>
            <Gallery></Gallery>
            </div>
            <div className='col-12 col-md-4 col-lg-4 col-xl-4 mt-2'>
            <Edebiyat></Edebiyat>
            </div>
            <div className='col-12 col-md-4 col-lg-4 col-xl-4 mt-2'>
          <Gunhafta></Gunhafta>
          </div>
          <div className='col-12 col-md-4 col-lg-4 col-xl-4 mt-2'>
          <Biliyormuydunuz></Biliyormuydunuz>
          </div>
          {/* <div className="col-12 mt-2">
          <Banner></Banner>
          </div> */}
        </div>
         
         

      {/* /.row (main row) */}
    </div>{/* /.container-fluid */}
  </section>
  {/* /.content */}
</div>

  </div>

  )
}

export default Home