

import React, { Component } from 'react';
import ReactDOM from "react-dom";
import Time from '../time';
import StartFirebase from '../../firebase';
import TextTransition, { presets } from 'react-text-transition';
import {ref,get,set,update,remove,child} from "firebase/database";
import { getDatabase,onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import { Bounce } from "react-swift-reveal";


class Duyuru extends React.Component {
    constructor(){
        super();
        
        this.state={
            duyuruId:[],
            duyuru:[],
            duyurular:'',
            active:0, 
        };        
    }
  getDuyuru()
  {
    var dbref=ref(StartFirebase());  
    get(child(dbref,"duyurular/")).then((snapshot)=>{
        this.setState({
           duyuruId:[],
           duyuru:[],
           duyurular:'',
      
                     })   
          let array = [];         
        snapshot.forEach((childSnapshot) => {
           
             
               const childData = childSnapshot.val();
               array.push(childData.duyuru);            
                     
        });
        // console.log(array);
        this.setState({ duyuru: array, loaded: true }); 
       //   console.log(this.state.bilgi);    
    }); 
  }
  componentDidMount(){
     this.timerID = setInterval(() => this.tick(), 10000); 
     this.getDuyuru();
   
   
  }
 
  componentWillUnmount() {
     clearInterval(this.timerID);
    this.setState({     
        duyuruId:[],
        duyuru:[],
        duyurular:'',
        active:0, 
    })
  }

  tick() {
    this.setState({                      
        active : this.state.active + 1,                                        
    });

    //
    const db = getDatabase();
    const commentsRef = ref(db, 'duyurular/');
  
    onChildChanged(commentsRef, (snapshot) => {       
       this.getDuyuru();  
    });
    onChildAdded(commentsRef, (snapshot) => {       
      this.getDuyuru();  
   });
   onChildRemoved(commentsRef, (snapshot) => {       
    this.getDuyuru();  
 });
  

    //
  }

  render() {
    return ( 
     
  
       
      
      <Bounce>
  <div>
  <h4 className='' style={{color:'white'}}><TextTransition springConfig={presets.default}>{this.state.duyuru[this.state.active % this.state.duyuru.length]} </TextTransition></h4> 
     </div>
      </Bounce> 
   
     
   

    
       
         
           
 
     
      
    );
  }
}

export default Duyuru
