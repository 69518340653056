

import React, { Component } from 'react';
import ReactDOM from "react-dom";
import Time from '../time';
import StartFirebase from '../../firebase';
import TextTransition, { presets } from 'react-text-transition';
import {ref,get,set,update,remove,child} from "firebase/database";
import { getDatabase,onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";


class Header extends React.Component {
    constructor(){
        super();
        
        this.state={
          bilgiId:[],
          bilgi:[],
          bilgiler:'',
          active:0, 
        };        
    }

  componentDidMount(){
     this.timerID = setInterval(() => this.tick(), 5000); 
     var dbref=ref(StartFirebase());  
     get(child(dbref,"bilgiler/")).then((snapshot)=>{
         this.setState({
            bilgi:[],
            bilgiId:[],
            active:0,
                      })   
           let genelarray = [];         
         snapshot.forEach((childSnapshot) => {
             if(childSnapshot.val().kategori=="Genel")  
              {
                const childData = childSnapshot.val();
                genelarray.push(childData.bilgi);            
            }          
         });
         this.setState({ bilgi: genelarray, loaded: true }); 
        //   console.log(this.state.bilgi);    
     });  
   
   
  }
 
  componentWillUnmount() {
     clearInterval(this.timerID);
    this.setState({     
      bilgiId:[],
      bilgi:[],
      bilgiler:'',
      active:0,
    })
  }

  tick() {
    this.setState({                      
        active : this.state.active + 1,                                        
    });

    //
    const db = getDatabase();
    const commentsRef = ref(db, 'bilgiler/');
  
    onChildChanged(commentsRef, (snapshot) => {       
        var dbref=ref(StartFirebase());  
       get(child(dbref,"bilgiler/")).then((snapshot)=>{
            this.setState({
               bilgi:[],
               bilgiId:[],
               active:0,
                         })   
              let genelarray = [];         
            snapshot.forEach((childSnapshot) => {
                if(childSnapshot.val().kategori=="Genel")  
                 {
                   const childData = childSnapshot.val();
                   genelarray.push(childData.bilgi);            
               }          
            });
            this.setState({ bilgi: genelarray, loaded: true }); 
           //   console.log(this.state.bilgi);    
        });  
    });
    
  

    //
  }

  render() {
    return ( 
       
       
            <p className='fw-bold text-info'>     <TextTransition springConfig={presets.wobbly}>{this.state.bilgi[this.state.active % this.state.bilgi.length]} </TextTransition>
            </p>
           
 
     
      
    );
  }
}

export default Header
