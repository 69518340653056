import React, {Component} from 'react';
import Moment from 'moment';
class Time extends Component {
  constructor() {
    super()
    let d = '2016-05-02T00:00:00'
    Moment.locale('tr') 
      this.state = {

        time: Moment(d.Time).format('HH:mm:ss')
      }
    this.countingSecond = this.countingSecond.bind(this)
  }
  countingSecond() {
    let d = new Date()
    this.setState({
    
        time: Moment(d.Time).format('HH:mm')
    })
  }
  componentWillMount() {
    setInterval(this.countingSecond, 1000)
  }
  render() {    
  return (
      <div>
         <p>{this.state.time}</p>     
      </div>
    )
  }
}
export default Time
