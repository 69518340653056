import ClockContainer from "../bilgiler/clock"
import TimeContainer from "../bilgiler/datetime"
import Weather from "../bilgiler/weather"
import Nobetciogretmen from "../bilgiler/nobetciogretmen"
import background from "../../assets/images/okulresim5.png"
import Muduryrd from "../bilgiler/muduryrd"
import Mudur from "../bilgiler/mudur"
import Ogrenci from "../bilgiler/ogrenci"
import {RubberBand} from "react-swift-reveal";
import './sidebar.css'
function SideNav() {
  return (
    <div>
<aside className="main-sidebar  side-inner" style={{backgroundImage: `url(${background})`}}>
  <div className="brand-link text-center text-white mb-3 shadow">
<RubberBand>
<TimeContainer></TimeContainer>
     <ClockContainer></ClockContainer>
</RubberBand>

 
  </div>
  {/* Sidebar */}
  <div className="sidebar" style={{marginTop:"130px"}}>
    {/* Sidebar user panel (optional) */}
    {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
      <div className="image">
        <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
      </div>
      <div className="info">
        <a href="/" className="d-block">Alexander Pierce</a>
      </div>
    </div> */}
    {/* SidebarSearch Form */}
    {/* <div className="form-inline">
      <div className="input-group" data-widget="sidebar-search">
        <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
        <div className="input-group-append">
          <button className="btn btn-sidebar">
            <i className="fas fa-search fa-fw" />
          </button>
        </div>
      </div>
    </div> */}
    {/* Sidebar Menu */}
    <nav className="mt-2">
      <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
        <div className="nav-item menu-open">
          <Mudur></Mudur>
          <Muduryrd></Muduryrd>
        <div class="card card-info card-outline bg-transparent text-light mb-2" style={{marginLeft:"0px"}}>
              <div class="card-header pb-0">
                <h4 class="card-title text-warning fw-bold fs-5">Nöbetçi Öğretmenler</h4>
             
              </div>
              <div class="card-body pb-1 pt-2">
               <Nobetciogretmen></Nobetciogretmen>
              </div>
            </div>
            <div class="card card-info card-outline bg-transparent text-light  mb-2" style={{marginLeft:"0px"}}>
              <div class="card-header pt-1 pb-1">
                <h4 class="card-title text-warning  fw-bold fs-5">Nöbetçi Öğrenciler</h4>
               
              </div>
              <div class="card-body pb-2 pt-1">
               <Ogrenci></Ogrenci>
           
              </div>
            </div>
            <div class="card card-info card-outline bg-transparent text-light" style={{marginLeft:"0px"}}>
            <div class="card-header pt-1 pb-1">
                <h4 class="card-title text-warning  fw-bold fs-5">Hava Durumu</h4>
               
              </div>
              <div class="card-body">
              <Weather></Weather>           
              </div>
            </div>
           
            {/* <div class="card card-primary card-outline bg-transparent text-light">
              <div class="card-header">
                <h5 class="card-title text-warning fw-bold">Özel Gün ve Haftalar</h5>
                <div class="card-tools">
                
                  <a href="#" class="btn btn-tool">
                    <i class="far fa-bookmark text-primary"></i>
                  </a>
                </div>
              </div>
              <div class="card-body">
                <p>Kaya Can Sütlü 12-A</p>
              
           
              </div>
            </div> */}
        </div>
       
      </ul>
    </nav>
  </div>
</aside>
</div>
  )
}

export default SideNav