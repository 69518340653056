import React, {Component} from 'react'

class ClockContainer extends Component {
  constructor() {
    super()
    let d = new Date()
      this.state = {
        hour: String(d.getHours()).padStart(2, '0'),
        minute: String(d.getMinutes()).padStart(2, '0'),
        second:String(d.getSeconds()).padStart(2, '0'),
        year: d.getFullYear(),
        time: d.toLocaleTimeString()
      }
    this.countingSecond = this.countingSecond.bind(this)
  }
  countingSecond() {
    let d = new Date()
    this.setState({
      hour: String(d.getHours()).padStart(2, '0'),
      minute: String(d.getMinutes()).padStart(2, '0'),
        second:String(d.getSeconds()).padStart(2, '0'),
        year: d.getFullYear(),
        time: d.toLocaleTimeString()
    })
  }
  componentWillMount() {
    setInterval(this.countingSecond, 1000)
  }
  render() {
    const months = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım","Aralık"]
    const days = ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"]
  return (
      <div className='fs-3'>
        <strong>{this.state.hour} : {this.state.minute}</strong> : {this.state.second}
     
      </div>
    )
  }
}
export default ClockContainer