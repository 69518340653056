//add geollocation watch position
import React, {Component} from 'react'
import './weather.scss'
import {RubberBand} from "react-swift-reveal";
const APIKEY = "910eb95f326630adfadde68e168c9e0a"
const url = "api.openweathermap.org/data/2.5/weather?"

const weatherIcon = {
  day: {
    2: 'wi-day-storm-showers',
    3: 'wi-day-sprinkle',
    5: 'wi-day-showers',
    6: 'wi-day-snow',
    7: 'wi-day-fog',
    8: 'wi-day-cloudy-high',
  },
  night: {
    2: 'wi-night-storm-showers',
    3: 'wi-night-alt-sprinkle',
    5: 'wi-night-alt-showers',
    6: 'wi-night-alt-snow',
    7: 'wi-night-fog',
    8: 'wi-night-alt-cloudy-high',
  }
}
class Weather extends  Component {
  render () {
    return (
      <div>
       <WeatherInfo>    
      
       </WeatherInfo>
      </div>
    )
  }
}

class WeatherInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      latitude: '36.856548',
      longitude: '28.260956',
      weatherDetails: {
      },
    }
  }
  componentDidMount () {
    this.getUserLocation()
  }
  getUserLocation(){
    this.setState({
      //       //   latitude: position.coords.latitude,
      //       //   longitude: position.coords.longitude, 
            latitude: '36.856548',
           longitude: '28.260956',
           },() => this.getWeatherDetails())
  }
  // getUserLocation () {
  //   if('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       this.setState({
  //       //   latitude: position.coords.latitude,
  //       //   longitude: position.coords.longitude, 
  //       latitude: '36.856548',
  //       longitude: '28.260956',
  //       },() => this.getWeatherDetails())
  //     })
  //   }
  //   else {
  //     throw new Error("Sorry!! your browser doesn't support geolocation");
  //   }
  // } 
  getWeatherDetails () {
  fetch(`https://${url}lat=${this.state.latitude}&lon=${this.state.longitude}&appid=${APIKEY}`)
      .then(result => {
      return result.json()
    })
    .then(data => {
      this.setState({
        weatherDetails: {
          humidity: data.main.humidity,
          location: data.name,
          temperature: data.main.temp,
          windSpeed: data.wind.speed,
          weatherCode: data.cod,
        } 
      },)
    })
  }
  render () {
    const { weatherCode, humidity, location, temperature, windSpeed} = this.state.weatherDetails 
    let icon
    let time = (new Date().getHours() < 12 ? 'day':'night') 
     if(weatherCode) {
      icon = weatherIcon[time][weatherCode.toString()[0]]
    }
    return (
      <RubberBand>
  <div className="row">
        <div className="col-6 text-left pr-0">
          <h4  style={{fontSize:'48px'}}>

            {(temperature - 274.15).toFixed(0)}
            <i className="wi wi-degrees"></i> 
            <i style={{color:'#fff', fontSize:'36px'}} className={`wi ${icon}`}></i>
          
                 
            
          </h4>
          </div>
          {/* <div className="col-6 text-left pt-1 pl-0">
          <i style={{color:'#13ADDB', fontSize:'36px'}} className={`wi ${icon}`}></i>
          </div> */}
          <div className="col-12 text-left">
          <span style={{fontSize:'20px',}} >{location}</span>
          <div className='mb-2 fs-6'>
          <i className="wi wi-raindrop"></i> %{humidity}  <i className="wi wi-small-craft-advisory ml-4"></i> {(windSpeed*18/5).toFixed(2)} Km/Saat
             </div>
         
          <div>
              
            </div>
        </div>
      
      </div>
      </RubberBand>
    
    )
  }
}
export default Weather