

import React, { Component } from 'react';

import StartFirebase from '../../firebase';

import {ref,get,set,update,remove,child} from "firebase/database";
import { getDatabase,onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import { HeadShake} from "react-swift-reveal";
import TextTransition, { presets } from 'react-text-transition';
import background from "../../assets/images/birbilgi.png"

class Gunhafta extends React.Component {
    constructor(){
        super();
        let d = new Date();
        this.state={
          bilgiId:[],
          baslik:[],
          metin:[],
          bstarih:[],
          btarih:[],
          active:0, 
          bugun:d.toLocaleDateString('en-CA'),
          yarin:d.toLocaleDateString('en-CA'),
          // bugun: d.getDate(),
          // yarin:d.getDate(),
        };        
    }
  getGunhafta()
  {
    let d = new Date()
    const days = ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"]
    var dbref=ref(StartFirebase());  
    get(child(dbref,"biliyormusunuz/")).then((snapshot)=>{
        this.setState({
          bilgiId:[],
          baslik:[],
          metin:[],
          bstarih:[],
          btarih:[],

                     })

             let arraybilgiId = [];         
                     let arrayname=[];
                     let arrayaciklama=[];
                     let arraybstarih=[];
                     let arraybttarih=[];                  
        snapshot.forEach((childSnapshot) => {
           
          // if(this.state.bugun>=childSnapshot.val().bstarih && this.state.bugun<=childSnapshot.val().bttarih )
          //      {
                const childData = childSnapshot.val();
                arrayname.push(childData.baslik); 
                arrayaciklama.push(childData.metin); 
                arraybilgiId.push(childData.key);
                arraybstarih.push(childData.bstarih); 
                arraybttarih.push(childData.bttarih); 
              //  }
                        
                     
        });
        this.setState({ 
          baslik:arrayname,
          metin:arrayaciklama,
          bilgiId:arraybilgiId,
          bstarih:arraybstarih,
          bttarih:arraybttarih,
          loaded: true }); 
     //   console.log(this.state.bilgi);    
 
      
        
    }); 
  }
  componentDidMount(){
     this.timerID = setInterval(() => this.tick(),25000); 
     this.getGunhafta()
   
   
  }
 
  componentWillUnmount() {
     clearInterval(this.timerID);
    this.setState({     
      bilgiId:[],
      baslik:[],
      metin:[],
      bstarih:[],
      btarih:[],
      active:0, 
    })
  }

  tick() {
    this.setState({                      
      active : this.state.active + 1,                                        
  });

    // let d = new Date()
    // this.setState({
    //   yarin:d.toLocaleDateString('en-CA'),   
    // });
    // if(this.state.day!==this.state.yarin)
    // {
    //   this.getGunhafta()
    // }
    const db = getDatabase();
    const commentsRef = ref(db, 'biliyormusunuz/');
  
    onChildChanged(commentsRef, (snapshot) => {       
       this.getGunhafta()  
    });
    onChildAdded(commentsRef, (snapshot) => {       
      this.getGunhafta()  
   });
   onChildRemoved(commentsRef, (snapshot) => {       
    this.getGunhafta()  
 });
  

    //
  }

  render() {
    return (        
      <HeadShake>
   <div className="rounded position-relative p-3 shadow" style={{backgroundImage: `url(${background})`, backgroundPosition:"center", backgroundSize:"cover"}}>
        <div className="ribbon-wrapper ribbon-lg">
          <div className="ribbon bg-primary">
            BİR BİLGİ
          </div>
        </div>
        <div className='text-white' style={{height:'100px'}}>
        <p ><strong className='text-warning fs-4'><TextTransition springConfig={presets.default}>{this.state.baslik[this.state.active % this.state.baslik.length]} </TextTransition></strong>
         </p>      
       
        </div>
        <div className='text-white' style={{height:'150px'}}>
       
        <p className='text-white fs-5'><TextTransition springConfig={presets.default}>{this.state.metin[this.state.active % this.state.baslik.length]} </TextTransition></p>
       
        </div>
      </div>
      </HeadShake>
       
   
  
    
      
       
    
      
   
 
    
       
         
           
 
     
      
    );
  }
}

export default Gunhafta
