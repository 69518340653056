

import React, { Component } from 'react';

import StartFirebase from '../../firebase';

import {ref,get,set,update,remove,child} from "firebase/database";
import { getDatabase,onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import {RubberBand, Spin} from "react-swift-reveal";


class Muduryrd extends React.Component {
    constructor(){
        super();
        let d = new Date();
        this.state={
            bilgiId:[],
            kat:[],
            gun:[],
            ogretmen:[],
            gorev:[],
            day: d.getDay(),
            yarin:d.getDay(),
        };        
    }
  getNobetciler()
  {
    let d = new Date()
    const days = ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"]
    var dbref=ref(StartFirebase());  
    get(child(dbref,"nobetciler/")).then((snapshot)=>{
        this.setState({
            bilgiId:[],
            kat:[],
            gun:[],
            ogretmen:[],
            gorev:[],
                     })   
                     let arraybilgiId = [];         
                     let arraykat=[];
                     let arraygun=[]; 
                     let arrayogretmen=[]; 
                     let arraygorev=[];           
        snapshot.forEach((childSnapshot) => {
           
               if((days[this.state.day]==childSnapshot.val().gun && childSnapshot.val().gorev==="mdryrd") )
               {
                const childData = childSnapshot.val();
                arraykat.push(childData.kat); 
                arraybilgiId.push(childData.key);
                arraygun.push(childData.gun); 
                arrayogretmen.push(childData.ogretmen);
                arraygorev.push(childData.gorev) 
               }
                        
                     
        });
        this.setState({ 
            gun:arraygun,
            bilgiId:arraybilgiId,
            kat:arraykat,
            ogretmen:arrayogretmen,
            gorev:arraygorev,
            loaded: true }); 
       //   console.log(this.state.bilgi);    
    }); 
  }
  componentDidMount(){
     this.timerID = setInterval(() => this.tick(),5000); 
     this.getNobetciler();
   
   
  }
 
  componentWillUnmount() {
     clearInterval(this.timerID);
    this.setState({     
      bilgiId:[],
      kat:[],
      gun:[],
      ogretmen:[],
      gorev:[],
    })
  }

  tick() {
   

    let d = new Date()
    this.setState({
    yarin: d.getDay(),     
    })
    if(this.state.day!==this.state.yarin)
    {
      this.getNobetciler();
    }
    const db = getDatabase();
    const commentsRef = ref(db, 'nobetciler/');
  
    onChildChanged(commentsRef, (snapshot) => {       
       this.getNobetciler();  
    });
    onChildAdded(commentsRef, (snapshot) => {       
      this.getNobetciler();  
   });
   onChildRemoved(commentsRef, (snapshot) => {       
    this.getNobetciler();  
 });
  

    //
  }

  render() {
    return ( 
       
      this.state.bilgiId.map((value,index) => (  
        <RubberBand>
    <div className="card card-info card-outline bg-transparent text-light mb-2" style={{marginLeft:"0px"}}>
        <div className="card-header pb-0">
          <h4 className="card-title text-warning fw-bold fs-5">
            Nöbetçi Mdr. Yrd. 
          </h4>
       
        </div>
        <div className="card-body text-white pb-2 pt-1" style={{fontSize:"24px",}}>
            <p><strong>{this.state.ogretmen[index]}</strong></p>
        </div>
      </div>
        </RubberBand>     
    
      ))
       
      
       
    
      
   
 
    
       
         
           
 
     
      
    );
  }
}

export default Muduryrd
