
import React from 'react'
import { Link } from 'react-router-dom';  
import Header from '../bilgiler/header';
import Duyuru from '../bilgiler/duyurular';
function Footer() {
  return (
   
    <div>
  <footer className="main-footer pb-3 pt-3" style={{backgroundColor:"#2989D8"}}>
         
  <div className="float-left d-none d-sm-inline-block mr-2">
      <h4 style={{fontStyle:"bold",color:'#ABEB21'}}>Duyurular :</h4>
    </div>
  
      <Duyuru></Duyuru>
   

  </footer>
</div>
  )
}

export default Footer