

import React, { Component } from 'react';

import StartFirebase from '../../firebase';
import './style.css'; 

import {ref,get,set,update,remove,child} from "firebase/database";
import { getDatabase,onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import background from "../../assets/images/logo2.png"


class Okul extends React.Component {
    constructor(){
        super();
        
        this.state={           
            bilgi:[],       
        };        
    }
  getBilgi()
  {
    var dbref=ref(StartFirebase());  
    get(child(dbref,"bilgiler/")).then((snapshot)=>{
        this.setState({
            bilgi:[],     
                     })   
                           
                     let arraybilgi=[];
                            
        snapshot.forEach((childSnapshot) => {
           
               if(childSnapshot.val().kategori=="okul")
               {
                const childData = childSnapshot.val();
                arraybilgi.push(childData.bilgi); 
               
               }
                        
                     
        });
        this.setState({ bilgi:arraybilgi,
           
           
            loaded: true }); 
       //   console.log(this.state.bilgi);    
    }); 
  }
  componentDidMount(){
     this.timerID = setInterval(() => this.tick(), 5000); 
   this.getBilgi();
   
   
  }
 
  componentWillUnmount() {
     clearInterval(this.timerID);
    this.setState({     
        bilgi:[],   
    })
  }

  tick() {
   

    //
    const db = getDatabase();
    const commentsRef = ref(db, 'bilgiler/');
  
    onChildChanged(commentsRef, (snapshot) => {       
       this.getBilgi();  
    });
    onChildAdded(commentsRef, (snapshot) => {       
      this.getBilgi();  
   });
   onChildRemoved(commentsRef, (snapshot) => {       
    this.getBilgi();  
 });
  

    //
  }

  render() {
    return ( 
       
      this.state.bilgi.map((value,index) => (       
        <span className='baslik' style={{color:"#F3E7E9", 
       //textShadow: "0 0.36px 1.896px #CCD1E7, -2px 1px #282958",
         letterSpacing:"1px",
        fontFamily:"", fontWeight:"bold", }}>  <img className="mt-0" src={background} alt="Okul adı"  style={{fontWeight:"bold",height:"35px" }} />&nbsp;  {value} &nbsp;</span>
         
      ))
       
      
       
    
      
   
 
    
       
         
           
 
     
      
    );
  }
}

export default Okul
