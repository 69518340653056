

import React, { Component } from 'react';

import StartFirebase from '../../firebase';

import {ref,get,set,update,remove,child} from "firebase/database";
import { getDatabase,onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import {RubberBand} from "react-swift-reveal";


class Ogrenci extends React.Component {
    constructor(){
        super();
        let d = new Date();
        this.state={
            bilgiId:[],
            kat:[],
            gun:[],
            no:[],
            ogretmen:[],
            bugun:d.toLocaleDateString('en-CA'),
            yarin:d.toLocaleDateString('en-CA'),
            // bugun: d.getDate(),
            // yarin:d.getDate(),
        };        
    }
  getNobetciler()
  {
    let d = new Date()
    const days = ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"]
    var dbref=ref(StartFirebase());  
    get(child(dbref,"nobetciogrenciler/")).then((snapshot)=>{
        this.setState({
            bilgiId:[],
            kat:[],
            gun:[],
            no:[],
            ogretmen:[],
                     })   
                     let arraybilgiId = [];         
                     let arraykat=[];
                     let arraygun=[];
                     let arrayogretmen=[];
                     let arrayno=[];          
        snapshot.forEach((childSnapshot) => {
           
               if(this.state.bugun==childSnapshot.val().tarih)
               {
                const childData = childSnapshot.val();
                arraykat.push(childData.kat); 
                arrayno.push(childData.no); 
                arraybilgiId.push(childData.key);
                arraygun.push(childData.gun); 
                arrayogretmen.push(childData.ogrenci); 
               }
                        
                     
        });
        this.setState({ 
            gun:arraygun,
            no:arrayno,
            bilgiId:arraybilgiId,
            kat:arraykat,
            ogretmen:arrayogretmen,
            loaded: true }); 
       //   console.log(this.state.bilgi);    
    }); 
  }
  componentDidMount(){
     this.timerID = setInterval(() => this.tick(),5000); 
     this.getNobetciler();
   
   
  }
 
  componentWillUnmount() {
     clearInterval(this.timerID);
    this.setState({     
      bilgiId:[],
      kat:[],
      gun:[],
      no:[],
      ogretmen:[],
    })
  }

  tick() {
   

    let d = new Date()
    this.setState({
      yarin:d.toLocaleDateString('en-CA'),    
    })
    if(this.state.bugun!==this.state.yarin)
    {
      this.getNobetciler();
    }
    const db = getDatabase();
    const commentsRef = ref(db, 'nobetciogrenciler/');
  
    onChildChanged(commentsRef, (snapshot) => {       
       this.getNobetciler();  
    });
    onChildAdded(commentsRef, (snapshot) => {       
      this.getNobetciler();  
   });
   onChildRemoved(commentsRef, (snapshot) => {       
    this.getNobetciler();  
 });
  

    //
  }

  render() {
    return ( 
       
      this.state.bilgiId.map((value,index) => (    
        <RubberBand>
      <p><strong style={{fontSize:"20px",}}>{this.state.ogretmen[index]}  </strong> <br/> <span style={{fontSize:"16px",}}>{this.state.kat[index]} </span> </p>
        </RubberBand>   
       
      ))
       
      
       
    
      
   
 
    
       
         
           
 
     
      
    );
  }
}

export default Ogrenci
