import React, { Component } from 'react';
import ReactDOM from "react-dom";
import Time from '../time';
import StartFirebase from '../../firebase';
import TextTransition, { presets } from 'react-text-transition';
import {ref,get,set,update,remove,child} from "firebase/database";
import { getDatabase,onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import ReactPlayer from 'react-player';
import mylogo from '../../assets/images/okullogo.png';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';
import { Zoom } from 'react-slideshow-image';

const spanStyle = {
  padding: '10px',
  marginTop:'300px',
  width:'100vh',
  textAlign:'center',
  // borderRadius:'5px',
  background: 'rgba(18, 3, 46, 0.5)',
  color: '#D6EF18',
  fontSize:"20px",
}

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: '100%',
  backgroundRepeat:'no-repeat',
  backgroundPosition:'center',
  height: '52vh',

  width:"100%",

}

const buttonStyle = {
  width: "0px",
  background: 'none',
  border: '0px'
};

const properties = {
  duration:4900,
    transitionDuration:3000,
  prevArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/></svg></button>,
  nextArrow: <button style={{ ...buttonStyle }}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/></svg></button>
}
class Gallery extends Component {
    constructor() {
        super();
        
        this.state = {
          ImageId:[],     
          url: [],
          active: 0,
          name:'',
          baslik:[],
          metin:[],          
          index:0,
        
          
        }
    }
    
    getVeri()
    {
        var dbref=ref(StartFirebase());
        get(child(dbref,"resimler/")).then((snapshot)=> { 
            this.setState ( {
                ImageId:[],     
                url: [],          
                name:'',
                baslik:[],
                metin:[],          
              
                  
              
            }) 
            let arrayimageId = [];         
            let arrayurl=[];
            let arraybaslik=[];  
            let arraymetin=[];  
            snapshot.forEach((childSnapshot) => {               
                const childData = childSnapshot.val();
                arrayurl.push(childData.url); 
                arraybaslik.push(childData.baslik);
                arrayimageId.push(childData.key); 
                arraymetin.push(childData.metin);               
           }); 
           this.setState({
            url: arrayurl,
            ImageId:arrayimageId,
            baslik:arraybaslik,
            metin:arraymetin,
            loaded: true }); 

      
         }) 
    }
    componentDidMount(){
        this.timerID = setInterval(() => this.tick(), 5000); 
        this.getVeri();
         
      } 
      componentWillUnmount()
      {
        this.setState ( {
            ImageId:[],     
            url: [],          
            name:'',
            baslik:[],
            metin:[],         
                    
        })
                  
      }   
      tick() {
         
        const db = getDatabase();

        const commentsRef = ref(db, 'resimler/');
        onChildChanged(commentsRef, (snapshot) => {
            this.getVeri();
        });
       
        onChildRemoved(commentsRef, (snapshot) => {
            this.getVeri();
        });
        onChildAdded(commentsRef, (snapshot) => {
            this.getVeri();
        });

      } 
      
      render(){
      
        return (
            <div className="slide-container shadow" style={{borderColor:"#251C52"}}>
             <Fade  {...properties}>
              {this.state.url.map((url, index) => (
                <div key={index}>
                   <div  style={{ ...divStyle, 'backgroundImage': `url(${url})` }}>
                
                <span style={this.state.baslik[index] ? spanStyle : {}}>{this.state.baslik[index]}
                  <br></br>
                   <span style={{fontSize:"15px",color:"white"}}>{this.state.metin[index]}</span> 
                </span>
                  
              </div>
                  
                </div>
              ))}
            </Fade>
          </div>
        )
            
    }


}
export default Gallery;