

import React, { Component } from 'react';

import StartFirebase from '../../firebase';

import {ref,get,set,update,remove,child} from "firebase/database";
import { getDatabase,onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import {RubberBand} from "react-swift-reveal";


class Mudur extends React.Component {
  constructor(){
    super();
    
    this.state={           
        bilgi:[],       
    };        
}
getBilgi()
{
var dbref=ref(StartFirebase());  
get(child(dbref,"bilgiler/")).then((snapshot)=>{
    this.setState({
        bilgi:[],     
                 })   
                       
                 let arraybilgi=[];
                        
    snapshot.forEach((childSnapshot) => {
       
           if(childSnapshot.val().kategori=="mudur")
           {
            const childData = childSnapshot.val();
            arraybilgi.push(childData.bilgi); 
           
           }
                    
                 
    });
    this.setState({ bilgi:arraybilgi,
       
       
        loaded: true }); 
   //   console.log(this.state.bilgi);    
}); 
}
componentDidMount(){
 this.timerID = setInterval(() => this.tick(), 5000); 
this.getBilgi();


}

componentWillUnmount() {
 clearInterval(this.timerID);
this.setState({     
    bilgi:[],   
})
}

tick() {


//
const db = getDatabase();
const commentsRef = ref(db, 'bilgiler/');

onChildChanged(commentsRef, (snapshot) => {       
   this.getBilgi();  
});
onChildAdded(commentsRef, (snapshot) => {       
  this.getBilgi();  
});
onChildRemoved(commentsRef, (snapshot) => {       
this.getBilgi();  
});


//
}


  render() {
    return ( 
       
      this.state.bilgi.map((value,index) => (    
        <RubberBand>
  <div className="card card-info card-outline bg-transparent text-light mb-2" style={{marginLeft:"0px"}}>
  <div className="card-header pb-0">
          <h4 className="card-title text-warning fw-bold fs-5">
           Okul Müdürü 
          </h4>
       
        </div>
        <div className="card-body text-white pb-2 pt-1" style={{fontSize:"24px",}}>
            <p><strong>{value}</strong></p>
        </div>
      </div>
        </RubberBand>   
      
      ))
       
      
       
    
      
   
 
    
       
         
           
 
     
      
    );
  }
}

export default Mudur
