import React from 'react'
import { Link } from 'react-router-dom';  
import Header from '../bilgiler/header';
import Okul from '../bilgiler/okul'
import background from "../../assets/images/okulresim2.png"
import '../SideNav/sidebar.css'
function AppHeader() {
 
  return (
    <div> 
   
   <nav className="main-header navbar navbar-expand  shadow navbar-dark navbar-dark" style={{backgroundColor:"#2989D8"}}>
  {/* Left navbar links */}
  <ul className="navbar-nav">
       <li className="nav-item">
      <a className="nav-link" data-widget="pushmenu" href="/" role="button"><i className="fas fa-bars" style={{color:"#2989D8"}}/></a>
    </li>
   </ul>
  <ul className="navbar-nav ml-auto text-center">
  
    {/* <h5 className='fw-bold' style={{color:"darkblue", fontWeight:"bold"}}>İHSAN MERMERCİ MESLEKİ VE TEKNİK ANAOLU LİSESİ</h5> */}
    {/* <img src="/img/okulname2.png" alt="Okul adı" className="" style={{ }} /> */}
<Okul></Okul>

</ul>
  {/* Right navbar links */}
  <ul className="navbar-nav ml-auto">

 
    {/* Notifications Dropdown Menu */}
   
    <li className="nav-item">
      <a className="nav-link" data-widget="fullscreen" href="#" role="button">
        <i className="fas fa-expand-arrows-alt" style={{color:"#2989D8"}} />
      </a>
    </li>
  
  </ul>
</nav>
</div>

    
  )
}

export default AppHeader