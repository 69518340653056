

import React, { Component } from 'react';

import StartFirebase from '../../firebase';

import {ref,get,set,update,remove,child} from "firebase/database";
import { getDatabase,onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import { Bounce } from "react-swift-reveal";
import Card from 'react-bootstrap/Card';
// import { LightSpeed } from "react-swift-reveal";
// import { Roll } from "react-swift-reveal";
// import { Flip } from "react-swift-reveal";
// import { Slide } from "react-swift-reveal";
class Cards extends React.Component {
    constructor(){
        super();
        
        this.state={
            bilgiId:[],
            bilgi:[],
            sayi:[],
            bilgiler:'',
            active:0, 
        };        
    }
  getBilgi()
  {
    var dbref=ref(StartFirebase());  
    get(child(dbref,"bilgiler/")).then((snapshot)=>{
        this.setState({
            bilgiId:[],
            bilgi:[],
            sayi:[],
            bilgiler:'',
            active:0, 
                     })   
                     let arraybilgiId = [];         
                     let arraybilgi=[];
                     let arraysayi=[];         
        snapshot.forEach((childSnapshot) => {
           
               if(childSnapshot.val().kategori=="alan")
               {
                const childData = childSnapshot.val();
                arraybilgi.push(childData.bilgi); 
                arraybilgiId.push(childData.key);
                arraysayi.push(childData.sayi); 
               }
                        
                     
        });
        this.setState({ bilgi:arraybilgi,
            sayi:arraysayi,
            bilgiId:arraybilgiId,
            loaded: true }); 
       //   console.log(this.state.bilgi);    
    }); 
  }
  componentDidMount(){
     this.timerID = setInterval(() => this.tick(), 10000); 
   this.getBilgi();
   
   
  }
 
  componentWillUnmount() {
     clearInterval(this.timerID);
    this.setState({     
        bilgiId:[],
        bilgi:[],
        sayi:[],
        bilgiler:'',
        active:0, 
    })
  }

  tick() {
   this.getBilgi();

    //
    const db = getDatabase();
    const commentsRef = ref(db, 'bilgiler/');
  
    onChildChanged(commentsRef, (snapshot) => {       
       this.getBilgi();  
    });
    onChildAdded(commentsRef, (snapshot) => {       
      this.getBilgi();  
   });
   onChildRemoved(commentsRef, (snapshot) => {       
    this.getBilgi();  
 });
  

    //
  }

  render() {
    return ( 
       
      this.state.bilgi.map((value,index) => (  
        <Bounce> 
           {/* <div className="col-lg-3 col-md-3 col-xl-3 col-12 mt-2"> 
           <Card border="danger"  style={{backgroundColor:'#0F0448',color:"white"}}>
            <Card.Header>{this.state.bilgi[index]}</Card.Header>
            <Card.Body>
            <Card.Title>{this.state.sayi[index]}</Card.Title>
            <Card.Text>

            </Card.Text>
            </Card.Body>
            </Card>
           </div> */}
           
       <div className="col-lg-3 col-md-3 col-xl-3 col-12 mt-1">
           {/* <div className={index===0 ? 'small-box bg-info' : index===1 ? 'small-box bg-success' : index===2 ? 'small-box bg-primary' : 'small-box bg-danger'}> */}
             <div className='small-box' style={{backgroundColor:"#2376BB",color:"white"}} >
             <div className="inner text-white">
               <h1 className='pl-2' style={{fontSize:'60px'}}>{this.state.sayi[index]}</h1>
               <p>&nbsp;</p>
             </div>
             <div className="icon"  style={{color:"#0E68B2"}}>
               <i className={index===0 ? 'fas fa-code' : index===1 ? 'fas fa-network-wired': index==2 ? 'fas fa-home': 'fas fa-users'} />
             </div> 
             
             <a href="/" className="small-box-footer text-left pl-3 fs-4" style={{backgroundColor:"#0E68B2"}}>{this.state.bilgi[index]}</a>
         
           </div>
           </div>
          </Bounce>
      ))
       
      
       
    
      
   
 
    
       
         
           
 
     
      
    );
  }
}

export default Cards
