import React, { Component } from 'react';
import ReactDOM from "react-dom";
import Time from '../time';
import './style.css'; 
import StartFirebase from '../../firebase';
import TextTransition, { presets } from 'react-text-transition';
import {ref,get,set,update,remove,child} from "firebase/database";
import { getDatabase,onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import ReactPlayer from 'react-player';
import mylogo from '../../assets/images/okullogo.png';
class Video extends React.Component {
    constructor() {
        super();
       
        this.state = {
          videoId:[],     
          url: [],
          active: 0,
          name:'',
          playing: true,
          muted: false,
          playbackRate: 1.0,      
          index:0,
          urls:{mylogo},
          
        }
      }
      getVideo()
      {
        var dbref=ref(StartFirebase());
        get(child(dbref,"videolar/")).then((snapshot)=>{ 
            this.setState({
                videoId:[],     
                url: [],
                active: 0,
                name:'',
                playing: true,
                muted: true,
                playbackRate: 1.0,     
                urls:{mylogo},
            })
            let arrayvideoId = [];         
            let arrayurl=[];
            let arrayname=[];
         snapshot.forEach((childSnapshot) => {
            
              
                const childData = childSnapshot.val();
                arrayurl.push(childData.url); 
                arrayname.push(childData.name);
                arrayvideoId.push(childData.key);           
                      
         });
         this.setState({
             url: arrayurl,
             videoId:arrayvideoId,
             name:arrayname,
             loaded: true }); 

        });
      }
      componentDidMount(){
        this.timerID = setInterval(() => this.tick(), 5000); 
        this.getVideo();
       
        } ;
        componentWillUnmount()
        {
          this.setState ( {
            videoId:[],     
            url: [],    
            name:'',
            playing: true,    
                
            
          })
                          
        }
        load = urls => {
            this.setState({
              index:0,
            })
          }
          _setIndex(value) {
            let d=value;
           
           
            value++;
           
            this.setState({})
            return (d === this.state.url.length-1) ?0 :value;  
         
         }
         tick() {
         
            const db = getDatabase();
            const commentsRef = ref(db, 'videolar/');
          
            onChildChanged(commentsRef, (snapshot) => {
             this.getVideo(); 
            });
            onChildRemoved(commentsRef, (snapshot) => {
              this.getVideo(); 
             });
             onChildAdded(commentsRef, (snapshot) => {
              this.getVideo(); 
             });
        }
        render() {
            const { muted,playbackRate} = this.state
        
            return (
              <div>
                  <ReactPlayer
                      className='react-player shadow' style={{borderColor:"#251C52"}}
                      controls
                      width='100%'
                      height='52vh'                   
                     url={this.state.url[this.state.index]} playing onEnded={() => this.setState({ index: this._setIndex(this.state.index)})}  
                                              
                      playbackRate={playbackRate}
                      muted={muted}
                      onReady={() => console.log('onReady')}
                      onStart={() => console.log('onStart')}
                     
                  />
                </div>
            )
          }
         
              

}
export default Video